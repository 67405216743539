import { Link } from "~components"
import React, { Fragment } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Images } from "~data"
import Footer from "./footerStyle"
import Promo from "./promoStyle"
import Cta from "./ctaStyle"
// TODO: condense into one style page

export default function FooterFourDark() {
  return (
    <Fragment>
      <Promo backgroundColor="#262729" id={"download"}>
        <Container>
          <Cta.MainBox
            backgroundShape={Images.projectManagement.CtaBg}
            backgroundColor="#64ac00"
          >
            <Row className="align-items-center">
              <Col className="col-lg-7">
                <Cta.Title as="h2" fontColor="#fff">
                  Try Monsai for free
                </Cta.Title>
              </Col>
              <Col className="col-lg-5 text-center text-lg-end">
                <a
                  href="https://play.google.com/store/apps/details?id=com.monsai"
                  target={"_blank"}
                >
                  <Cta.Image src={Images.GooglePlay} alt="google play" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/monsai-build-a-life-you-love/id6475244334"
                  target={"_blank"}
                >
                  <Cta.Image src={Images.AppStore} alt="app store" />
                </a>
              </Col>
            </Row>
          </Cta.MainBox>
        </Container>
      </Promo>
      <Footer backgroundColor="#262729">
        <Container>
          <Footer.Box pb="30px">
            <Row className="justify-content-center justify-content-lg-between">
              <Col
                xs="12"
                className="col-6 col-xxs-4 col-lg-4 col-md-5 col-xs-4"
              >
                <Footer.Logo>
                  {/* Brand Logo*/}
                  <Footer.Box mb="30px" mbMD="0">
                    <Link to="#">
                      <img src={Images.FooterFourDark.LogoWhite} alt="logo" />
                    </Link>
                  </Footer.Box>
                </Footer.Logo>
              </Col>
              <Col xs="12" className="col-lg-8 col-md-7">
                <Footer.Menu>
                  <Footer.MenuItems>
                    <a href="/">Home</a>
                  </Footer.MenuItems>
                  <Footer.MenuItems>
                    <a href="/blog/">Blog</a>
                  </Footer.MenuItems>
                  <Footer.MenuItems>
                    <a href="/terms/">Terms</a>
                  </Footer.MenuItems>
                  <Footer.MenuItems>
                    <a href="/privacy/">Privacy</a>
                  </Footer.MenuItems>
                  <Footer.MenuItems>
                    <a href="/faqs/">FAQs</a>
                  </Footer.MenuItems>
                </Footer.Menu>
              </Col>
            </Row>
          </Footer.Box>
          <Footer.Copyright>
            <Footer.CopyrightText>
              © 2025 Monsai. All Rights Reserved
            </Footer.CopyrightText>
            <Footer.SocialShare>
              <Footer.SocialShareItem>
                <a
                  href="https://fb.me/monsaiapp"
                  target={"_blank"}
                  aria-label="Facebook"
                >
                  <i className="fab fa-facebook-square" />
                </a>
              </Footer.SocialShareItem>
              <Footer.SocialShareItem>
                <a
                  href="https://www.instagram.com/monsaiapp/"
                  target={"_blank"}
                  aria-label="Instagram"
                >
                  <i className="fab fa-instagram" />
                </a>
              </Footer.SocialShareItem>
              <Footer.SocialShareItem>
                <a
                  href="https://twitter.com/monsaiapp"
                  target={"_blank"}
                  aria-label="Twitter"
                >
                  <i className="fab fa-twitter" />
                </a>
              </Footer.SocialShareItem>
            </Footer.SocialShare>
          </Footer.Copyright>
        </Container>
      </Footer>
    </Fragment>
  )
}
